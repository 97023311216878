<template>
  <div
    class="modal fade"
    id="queryModal"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby=""
    aria-hidden="true"
  >
    <div class="modal-dialog" style="max-width: 600px;" role="document">
      <div class="modal-content bg-dashboard-dark-1 h-100" v-if="show">
        <div class="modal-header shadow-sm border-outline-dark-bottom">
          <h6
            class="modal-title font-weight-bold text-body"
            id=""
          >
            <p class="mb-1">Violation</p>
            
          </h6>
          <button
            type="button"
            class="close text-body"
            aria-label="Close"
            @click="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="w-100">
            <div class="position-relative">
              <img
                class="w-100" style="min-height: 250px;"
                v-if="violation && violation.attributes.image.data && violation.attributes.image.data.length"
                :src="violation.attributes.image.data[0].attributes.url"
              />
              <img src="@/assets/image_placeholder.png" alt="" v-else />
            </div>
            <div class="w-100">
              <div v-if="violation">
                <p class="pt-3 mb-0">
                  {{violation.attributes.name}}
                </p>
                <p class="">
                  {{formatDate(violation.attributes.timestamp)}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-spinner v-if="isLoading" />
  </div>
</template>

<script>
import $ from "jquery";
import API from "@/api";
import LoadingSpinner from "@/components/LoadingSpinner";

import Moment from "moment";
import Swal from "sweetalert2";
import moment from "moment";
import Axios from "axios";

export default {
  name: "query-modal",
  props: ["show", "id", "close"],
  components: { LoadingSpinner },
  data() {
    return {
      isLoading: false,
      violation: null
    };
  },
  watch: {
    
    show: {
      async handler() {
          console.log(this.show, this.id)
        if (this.show) {
          setTimeout(() => {

            $("#queryModal").modal("show");
            this.getViolation()
          },500)
          
        } else {
          setTimeout(() => {

            $("#queryModal").modal("hide");
          },500)
        }
      },
      immediate: true,
    },
  },
  methods: {
    getViolation(){
      API.get("violations/"+this.id+"?populate=camera,image,annotatedImage")
        .then((retVal) => {
          console.log(retVal);
          this.violation = retVal.data
        })
        .catch((err) => {
          console.error(err);
        });
    },
    formatDate(date) {
      return Moment(date).format("DD/MM/YYYY h:mm A");
    },
    closeModal() {
      console.log('close modal');
      this.$emit("close");
    },
  },
  // mounted(){
  //   console.log(this.id)
  // }
};
</script>